import moment from 'moment'
import Chart from 'chart.js'
import { ServiceFactory } from '@/services/ServiceFactory'
// import ZbConditionModal from './special-condition-modal'
// const _specialConditionsService = ServiceFactory.get('SpecialConditionsService')
// const _barsService = ServiceFactory.get('BarsService')
const _urlCounterService = ServiceFactory.get('UrlCounterService')
export default {
  // components: {
  //   ZbConditionModal
  // },
  data () {
    return {
      // settings: [],
      // conditions: [],
      // loading: false,
      // currentItem: null,
      // barId: ''
      // secMeasureId: {
      //   zbSecurityMeasureId: ''
      // }
      url: '',
      headers: [
        {
          text: 'Service From',
          align: 'start',
          sortable: false,
          value: 'serviceFrom'
        },
        { text: 'Total Android', align: 'center', sortable: false, value: 'android' },
        { text: 'Total iOS', align: 'center', sortable: false, value: 'ios' },
        { text: 'Total otros', align: 'center', sortable: false, value: 'others' },
        { text: 'Click totales', align: 'center', sortable: false, value: 'total' },
        { text: '', value: 'data-table-expand' }
        // { text: 'Opciones', value: 'options' }
      ],
      items: [],
      loading: false,
      menu2: false,
      dates: [],
      chartData: {},
      chart: [],
      pieChart: {},
      expanded: [],
      singleExpand: true,
      chartService: null,
      chServData: {},
      pieChartServ: null,
      pieChServData: {},
      loadingChart: false,
      loadingPicker: false,
      total: null,
      historic: null,
      chartAmp: false,
      pAndroid: 0.0,
      pIos: 0.0,
      pOther: 0.0
    }
  },
  watch: {
    expanded: async function () {
      if (this.expanded.length > 0) {
        this.loadingChart = true
        if (this.chartService) {
          this.chartService.destroy()
        }
        if (this.pieChartServ) {
          this.pieChartServ.destroy()
        }
        // console.log(this.expanded)
        var servStatsResp = await _urlCounterService.servStats(this.dates[1], this.dates[0], this.url, this.expanded[0].serviceFrom)
        this.loadingChart = false
        // console.log(servStatsResp)
        setTimeout(() => {
          this.chServData = {
            labels: servStatsResp.dataset.labels,
            datasets: [
              {
                label: 'Clicks',
                data: servStatsResp.dataset.data,
                borderColor: '#ff5a5f'
                // backgroundColor: chartDatasetsBackground
              }
            ]
          }
          var chId = 'chart_' + this.expanded[0].serviceFrom
          var canvas = document.getElementById(chId).getContext('2d')
          this.chartService = new Chart(canvas, {
            type: 'line',
            data: this.chServData,
            options: {
              title: {
                display: true,
                text: 'Click por dia'
              },
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true
                }
              },
              legend: { display: false }
            }
          })

          this.pieChServData = {
            labels: servStatsResp.os.labels,
            datasets: [
              {
                label: 'Pie',
                data: servStatsResp.os.data,
                backgroundColor: ['Green', 'Yellow', 'Orange']
              }
            ]
          }
          var chId2 = 'pie_' + this.expanded[0].serviceFrom
          var canvas2 = document.getElementById(chId2).getContext('2d')
          // var canvas2 = document.getElementById('chartPie').getContext('2d')
          this.pieChartServ = new Chart(canvas2, {
            type: 'pie',
            data: this.pieChServData,
            options: {
              title: {
                display: true,
                text: 'Clicks por S.O.'
              },
              responsive: true,
              legend: { display: false }
            }
          })
        }, 1000)
      } else {
        this.chartService = null
        this.pieChartServ = null
      }
    }
  },
  mounted () {
    // this.indexSecurityMeasures()
    this.initialize()
  },
  methods: {
    // async urlCounter() {},
    async initialize () {
      var date1 = moment().format('YYYY-MM-DD')
      var date2 = moment().subtract(14, 'days').format('YYYY-MM-DD')
      this.dates = [date2, date1]
      console.log(date1, date2)

      // this.currentItem = null
      if (this.$route.params.url) {
        this.loading = true
        this.url = this.$route.params.url
        try {
          console.log(this.url)
          const destailResp = await _urlCounterService.urlDetail(this.url)
          this.items = destailResp.url
          this.loading = false
          console.log(destailResp)
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las medidas de seguridad del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
        }
      }

      // chart settings
      try {
        var urlStatsResp = await _urlCounterService.urlStats(this.dates[1], this.dates[0], this.url)
        console.log(urlStatsResp)
        if (urlStatsResp.total > 0) {
          this.pAndroid = Math.round((urlStatsResp.os.data[0] / urlStatsResp.total) * 10000) / 100
          this.pIos = Math.round((urlStatsResp.os.data[1] / urlStatsResp.total) * 10000) / 100
          this.pOther = Math.round((urlStatsResp.os.data[2] / urlStatsResp.total) * 10000) / 100
        } else {
          this.pAndroid = 0
          this.pIos = 0
          this.pOther = 0
        }
        this.total = urlStatsResp.total
        this.historic = urlStatsResp.historic
        this.chartData = {
          labels: urlStatsResp.dataset.labels,
          datasets: [
            {
              label: 'Clicks',
              data: urlStatsResp.dataset.data,
              borderColor: '#ff5a5f'
              // backgroundColor: chartDatasetsBackground
            }
          ]
        }
        var canvas = document.getElementById('chart').getContext('2d')
        this.chart[0] = new Chart(canvas, {
          type: 'line',
          data: this.chartData,
          options: {
            title: {
              display: true,
              text: 'Click por dia'
            },
            responsive: true,
            scales: {
              y: {
                beginAtZero: true
              }
            },
            legend: { display: false }
          }
        })
        this.pieChart = {
          labels: urlStatsResp.os.labels,
          datasets: [
            {
              label: 'Pie',
              data: urlStatsResp.os.data,
              backgroundColor: ['Green', 'Yellow', 'Orange']
            }
          ]
        }
        var canvas2 = document.getElementById('chartPie').getContext('2d')
        this.chart[1] = new Chart(canvas2, {
          type: 'pie',
          data: this.pieChart,
          options: {
            title: {
              display: true,
              text: 'Clicks por S.O.'
            },
            responsive: true,
            // scales: {
            //   y: {
            //     beginAtZero: true
            //   }
            // },
            legend: { display: false }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async changeData () {
      console.log(this.dates)
      try {
        this.loadingPicker = true
        var urlStatsResp = await _urlCounterService.urlStats(this.dates[1], this.dates[0], this.url)
        this.total = urlStatsResp.total
        this.historic = urlStatsResp.historic
        console.log(urlStatsResp)
        this.chart[0].config.data.labels = urlStatsResp.dataset.labels
        this.chart[0].config.data.datasets[0].data = urlStatsResp.dataset.data
        this.chart[0].update()
        this.chart[1].config.data.labels = urlStatsResp.os.labels
        this.chart[1].config.data.datasets[0].data = urlStatsResp.os.data
        this.chart[1].update()
        if (urlStatsResp.total > 0) {
          this.pAndroid = Math.round((urlStatsResp.os.data[0] / urlStatsResp.total) * 10000) / 100
          this.pIos = Math.round((urlStatsResp.os.data[1] / urlStatsResp.total) * 10000) / 100
          this.pOther = Math.round((urlStatsResp.os.data[2] / urlStatsResp.total) * 10000) / 100
        } else {
          this.pAndroid = 0
          this.pIos = 0
          this.pOther = 0
        }
        if (this.chartService && this.pieChartServ) {
          var servStatsResp = await _urlCounterService.servStats(this.dates[1], this.dates[0], this.url, this.expanded[0].serviceFrom)
          // console.log(servStatsResp)
          this.chartService.config.data.labels = servStatsResp.dataset.labels
          this.chartService.config.data.datasets[0].data = servStatsResp.dataset.data
          this.chartService.update()
          this.pieChartServ.config.data.labels = servStatsResp.os.labels
          this.pieChartServ.config.data.datasets[0].data = servStatsResp.os.data
          this.pieChartServ.update()
        }
        this.loadingPicker = false
      } catch (error) {
        console.log(error)
      }
    },
    amplify () {
      var chCol = document.getElementById('chartCol')
      this.chartAmp = !this.chartAmp
      console.log(chCol, this.chartAmp)
      if (this.chartAmp) {
        chCol.className = 'col-12 text-center'
      } else {
        chCol.className = 'col-9 text-center'
      }
    },
    goBack () {
      this.$router.push({ name: 'url-counter' }).catch(() => {})
    }
    // editItem (item) {
    //   console.log(item)
    //   this.currentItem = item
    //   // this.dialog2 = true
    // },
    // deleteItem (item) {
    //   this.$swal({
    //     title: '¿Está seguro de eliminar condición especial "' + item.name + '"?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sí, eliminar',
    //     cancelButtonText: 'Cancelar'
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       try {
    //         await _specialConditionsService.destroy(item.id)
    //         this.$swal(
    //           'Condición especial eliminado',
    //           'Se eliminó correctamente',
    //           'success'
    //         ).then(async (result) => {
    //           this.initialize()
    //         })
    //       } catch (error) {
    //         this.$notify({
    //           title: 'Error:',
    //           type: 'error',
    //           text: 'Condición especial no se pudo eliminar.'
    //         })
    //       }
    //     }
    //   })
    // },
    // async indexSecurityMeasures () {
    //   if (this.$route.params.id) {
    //     // console.log(this.$route.params.id)
    //     try {
    //       // this.securityMeasures = await _securityMeasuresService.show(this.$route.params.id)
    //       // console.log(this.securityMeasures)
    //       // const getBar = await _barsService.show(this.$store.state.companiesModule.companyData.zbBarId)
    //       // console.log(this.securityMeasures.securityMeasure)
    //       // console.log(getBar.bar.securityMeasures[0].zbSecurityMeasureId)
    //       // this.securityMeasures = this.securityMeasures.securityMeasure.map((v, i) => {
    //       //   if (getBar.bar.securityMeasures[i]) {
    //       //     if (v.zbSecurityMeasureId === getBar.bar.securityMeasures[i].zbSecurityMeasureId) {
    //       //       return {
    //       //         active: true,
    //       //         description: v.description,
    //       //         image: v.image,
    //       //         name: v.name,
    //       //         zbSecurityMeasureId: v.zbSecurityMeasureId
    //       //       }
    //       //     } else {
    //       //       return {
    //       //         active: false,
    //       //         description: v.description,
    //       //         image: v.image,
    //       //         name: v.name,
    //       //         zbSecurityMeasureId: v.zbSecurityMeasureId
    //       //       }
    //       //     }
    //       //   } else {
    //       //     return {
    //       //       active: false,
    //       //       description: v.description,
    //       //       image: v.image,
    //       //       name: v.name,
    //       //       zbSecurityMeasureId: v.zbSecurityMeasureId
    //       //     }
    //       //   }
    //       // })
    //     } catch (error) {
    //       // console.info(error)
    //       this.$notify({
    //         type: 'error',
    //         text: 'Algo salió mal al consultar las medidas de seguridad del negocio, vuelva a la lista de negocios e intente nuevamente'
    //       })
    //     }
    //   }
    // },
    // async checkSecurityMeasure (itemId, value) {
    //   // console.log(itemId, !value)
    //   try {
    //     this.secMeasureId.zbSecurityMeasureId = itemId
    //     // const res = await _securityMeasuresService.editBarMeasure(this.$route.params.id, this.secMeasureId)
    //     // console.log(res)
    //     this.indexSecurityMeasures()
    //     this.$notify({
    //       type: 'success',
    //       text: 'Datos modificados correctamente'
    //     })
    //   } catch (error) {
    //     // console.log(error)
    //     this.$notify({
    //       type: 'error',
    //       text: 'Ocurrio un error, intentelo nuevamente'
    //     })
    //   }
    // },
    // close () {
    //   this.$router.push({ path: 'companies' }).catch(() => {})
    // }
  }
}
